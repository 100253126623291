import AssetTypeSelect from '@/modules/asset-types/components/AssetTypeSelect';
import { AssetTypeModel } from '@/modules/asset-types/types/AssetTypeModel';
import { Alert, Button, TextField } from '@mui/material';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import CreateNewOrUseExistingToggleButton from '../components/CreateNewOrUseExistingToggleButton';
import { AssetSelect } from '@/modules/assets/components/AssetSelect';
import { AssetModel } from '@/modules/assets/types/AssetModel';
import { TrackerType } from '@/modules/trackers';
import MobilePage from '../components/MobilePage';
import MobilePageHeader from '../components/MobilePageHeader';
import MobilePageContent from '../components/MobilePageContent';
import { trackerService } from '@/modules/trackers/api/trackers/tracker.service';
import { toast } from 'react-toastify';
import { TrackerModel } from '@/modules/trackers/types/TrackerModel';
import { assetService } from '@/modules/assets/api/assets/asset.service';
import { isNil } from 'lodash-es';
import ScanBarcodeButton from '../components/ScanBarcodeButton';
import { TrackerTypeChip } from '@/modules/trackers/components/TrackerTypeChip';
import { ApiResponseCode } from '@/lib/api/api-response-code';

interface ConnectAssetPageProps {}

const ConnectAssetPage: FunctionComponent<ConnectAssetPageProps> = () => {
  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState<number>();
  const [newAssetCode, setNewAssetCode] = useState<string>('');
  const [useExistingAsset, setUseExistingAsset] = useState(false);
  const [existingAsset, setExistingAsset] = useState<AssetModel | null>(null);

  const [newTrackerBarcode, setNewTrackerBarcode] = useState<string>('');
  const [newTrackerUnaTagIdentifier, setNewTrackerUnaTagIdentifier] = useState<string>('');
  const [newTrackerBleIdentifier, setNewTrackerBleIdentifier] = useState<string>('');

  // a value getter that retuns the newTrackerUnaTagIdentifier, but without the prefix 0 if it contains that
  const getNewTrackerUnaTagIdentifierWithoutZeroPrefix = () => {
    // Check if the identifier starts with '0' and remove it
    return newTrackerUnaTagIdentifier.startsWith('0') ? newTrackerUnaTagIdentifier.substring(1) : newTrackerUnaTagIdentifier;
  };

  const [newTrackerEpcCode, setNewTrackerEpcCode] = useState<string>('');

  const [validationErrors, setValidationErrors] = useState<string[]>([]);

  function onSelectedAssetTypeIdChanged(selectedAssetType?: AssetTypeModel | undefined) {
    setSelectedAssetTypeId(selectedAssetType?.id);
  }

  function onNewAssetCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setNewAssetCode(e.target.value);
  }

  const onUseExistingAssetChanged = (newValue: boolean) => {
    if (!newValue) {
      setExistingAsset(null);
    }
    setUseExistingAsset(newValue);
  };

  function onAssetSelected(asset: AssetModel | null) {
    setExistingAsset(asset);
  }

  function onTrackerBarcodeScan(barcode: string) {
    setNewTrackerBarcode(barcode);
  }

  function onAssetCodeScanned(barcode: string) {
    setNewAssetCode(barcode);
  }

  function onNewTrackerBarcodeChange(e: ChangeEvent<HTMLInputElement>) {
    setNewTrackerBarcode(e.target.value);
  }

  function onNewTrackerUnaTagIdentifierChange(e: ChangeEvent<HTMLInputElement>) {
    setNewTrackerUnaTagIdentifier(e.target.value);
  }

  function onNewTrackerUnaTagIdentifierScan(barcode: string) {
    setNewTrackerUnaTagIdentifier(barcode);
  }

  function onNewTrackerBleIdentifierChange(e: ChangeEvent<HTMLInputElement>) {
    setNewTrackerBleIdentifier(e.target.value);
  }

  function onNewTrackerBleIdentifierScan(barcode: string) {
    setNewTrackerBleIdentifier(barcode);
  }

  function onNewTrackerEpcCodeChange(e: ChangeEvent<HTMLInputElement>) {
    setNewTrackerEpcCode(e.target.value);
  }

  function onNewTrackerEpcCodeScan(barcode: string) {
    setNewTrackerEpcCode(barcode);
  }

  async function onExistingAssetCodeScanned(barcode: string) {
    const existingAsset = await assetService.getByCode(barcode);
    if (existingAsset.isSuccess) {
      setExistingAsset(existingAsset.payload);
    } else {
      if (existingAsset.responseCode === ApiResponseCode.NOT_FOUND) {
        toast.error('Asset not found');
      } else {
        toast.error('Error fetching existing Asset');
      }
    }
  }

  function validateFormValues({
    useExistingAsset,
    existingAssetId,
    newTrackerBarcode,
    newTrackerEpcCode,
    newTrackerUnaTagIdentifier,
    newTrackerBleIdentifier,
    selectedAssetTypeId,
    newAssetCode,
  }: {
    useExistingAsset: boolean;
    existingAssetId: number | undefined;
    newTrackerBarcode: string;
    newTrackerEpcCode: string;
    newTrackerUnaTagIdentifier: string;
    newTrackerBleIdentifier: string;
    selectedAssetTypeId: number | undefined;
    newAssetCode: string;
  }): string[] {
    const validationErrors: string[] = [];

    if (useExistingAsset) {
      if (!existingAssetId) {
        validationErrors.push('No Asset selected');
      }
    } else {
      if (!selectedAssetTypeId) {
        validationErrors.push('No Asset Type selected');
      }

      if (!newAssetCode) {
        validationErrors.push('No Asset Code entered');
      }
    }

    if (!newTrackerBarcode && !newTrackerEpcCode && !newTrackerUnaTagIdentifier && !newTrackerBleIdentifier) {
      validationErrors.push('No Tracker Identifier entered');
    }

    return validationErrors;
  }

  async function onLinkAssetClicked() {
    const validationErrors: string[] = validateFormValues({
      useExistingAsset,
      existingAssetId: existingAsset?.id,
      newTrackerBarcode,
      newTrackerUnaTagIdentifier: getNewTrackerUnaTagIdentifierWithoutZeroPrefix(),
      newTrackerBleIdentifier,
      selectedAssetTypeId,
      newTrackerEpcCode,
      newAssetCode,
    });

    setValidationErrors(validationErrors);

    // If not all fields are filled in, return
    if (validationErrors.length > 0) {
      return;
    }

    let existingBarcodeTracker: TrackerModel | undefined;
    let existingEpcTracker: TrackerModel | undefined;
    let existingUnaTagTracker: TrackerModel | undefined;
    let existingBleTracker: TrackerModel | undefined;

    // Check if the Tracker already exists
    if (newTrackerBarcode) {
      const doesBarcodeTrackerExist = await trackerService.getByIdentifier({ barcode: newTrackerBarcode });

      if (!doesBarcodeTrackerExist.isSuccess) {
        if (doesBarcodeTrackerExist.responseCode === ApiResponseCode.NOT_FOUND) {
          // Barcode Tracker does not exist, continue
        } else {
          toast.error("Error linking Asset to Tracker, couldn't fetch existing Trackers");
          return;
        }
      }

      if (doesBarcodeTrackerExist.payload && doesBarcodeTrackerExist.payload.asset) {
        toast.error('Barcode Tracker is already linked to an Asset, unlink it first in the Desktop site');
        return;
      }
    }

    if (newTrackerEpcCode) {
      const doesEpcTrackerExist = await trackerService.getByIdentifier({ epc: newTrackerEpcCode });

      if (!doesEpcTrackerExist.isSuccess) {
        if (doesEpcTrackerExist.responseCode === ApiResponseCode.NOT_FOUND) {
          // EPC Tracker does not exist, continue
        } else {
          toast.error("Error linking Asset to Tracker, couldn't fetch existing Trackers");
          return;
        }
      }

      if (doesEpcTrackerExist.payload && doesEpcTrackerExist.payload.asset) {
        toast.error('RFID Tracker is already linked to an Asset, unlink it first in the Desktop site');
        return;
      }
    }

    if (getNewTrackerUnaTagIdentifierWithoutZeroPrefix()) {
      const doesUnaTagTrackerExist = await trackerService.getByIdentifier({ deviceId: getNewTrackerUnaTagIdentifierWithoutZeroPrefix() });

      if (!doesUnaTagTrackerExist.isSuccess) {
        if (doesUnaTagTrackerExist.responseCode === ApiResponseCode.NOT_FOUND) {
          // UnaTag Tracker does not exist, continue
        } else {
          toast.error("Error linking Asset to Tracker, couldn't fetch existing Trackers");
          return;
        }
      }

      if (doesUnaTagTrackerExist.payload && doesUnaTagTrackerExist.payload.asset) {
        toast.error('UnaTag Tracker is already linked to an Asset, unlink it first in the Desktop site');
        return;
      }
    }

    if (newTrackerBleIdentifier) {
      const doesBleTrackerExist = await trackerService.getByIdentifier({ deviceId: newTrackerBleIdentifier });

      if (!doesBleTrackerExist.isSuccess) {
        if (doesBleTrackerExist.responseCode === ApiResponseCode.NOT_FOUND) {
          // BLE Tracker does not exist, continue
        } else {
          toast.error("Error linking Asset to Tracker, couldn't fetch existing Trackers");
          return;
        }
      }

      if (doesBleTrackerExist.payload && doesBleTrackerExist.payload.asset) {
        toast.error('BLE Tracker is already linked to an Asset, unlink it first in the Desktop site');
        return;
      }
    }

    // Check if asset code already is present
    if (!useExistingAsset) {
      const doesAssetExist = await assetService.getByCode(newAssetCode);

      if (doesAssetExist.isSuccess) {
        toast.error('Asset code already exists');
        return;
      } else if (doesAssetExist.responseCode === ApiResponseCode.NOT_FOUND) {
        // Asset does not exist, continue
      } else {
        toast.error("Error linking Asset to Tracker, couldn't fetch existing Assets");
        return;
      }
    }

    // Link Tracker to Asset
    linkTrackerToAsset({
      existingAssetId: existingAsset?.id,
      selectedAssetTypeId,
      newAssetCode,
      existingBarcodeTracker,
      existingEpcTracker,
      existingUnaTagTracker,
      existingBleTracker,
    });
  }

  async function linkTrackerToAsset({
    existingAssetId,
    selectedAssetTypeId,
    newAssetCode,
    existingBarcodeTracker,
    existingEpcTracker,
    existingUnaTagTracker,
    existingBleTracker,
  }: {
    existingAssetId: number | undefined;
    selectedAssetTypeId: number | undefined;
    newAssetCode: string;
    existingBarcodeTracker: TrackerModel | undefined;
    existingEpcTracker: TrackerModel | undefined;
    existingUnaTagTracker: TrackerModel | undefined;
    existingBleTracker: TrackerModel | undefined;
  }) {
    let newBarcodeTrackerId: number | undefined;
    let newEpcTrackerId: number | undefined;
    let newUnaTagTrackerId: number | undefined;
    let newBleTrackerId: number | undefined;

    // Create new Barcode Tracker
    if (existingBarcodeTracker) {
      newBarcodeTrackerId = existingBarcodeTracker.id;
    } else if (newTrackerBarcode) {
      const newBarcodeTrackerResponse = await trackerService.create({
        barcode: newTrackerBarcode,
        type: TrackerType.Barcode,
      });

      if (!newBarcodeTrackerResponse.isSuccess) {
        toast.error("Error linking Asset to Tracker, couldn't create new Barcode Tracker");
        return;
      }

      newBarcodeTrackerId = newBarcodeTrackerResponse.payload.id;
    }

    // Create new EPC Tracker
    if (existingEpcTracker) {
      newEpcTrackerId = existingEpcTracker.id;
    } else if (newTrackerEpcCode) {
      const newEpcTrackerResponse = await trackerService.create({
        epc: newTrackerEpcCode,
        type: TrackerType.RFID,
      });

      if (!newEpcTrackerResponse.isSuccess) {
        toast.error("Error linking Asset to Tracker, couldn't create new RFID Tracker");
        return;
      }

      newEpcTrackerId = newEpcTrackerResponse.payload.id;
    }

    // Create new UnaTag Tracker
    if (existingUnaTagTracker) {
      newUnaTagTrackerId = existingUnaTagTracker.id;
    } else if (getNewTrackerUnaTagIdentifierWithoutZeroPrefix()) {
      const newUnaTagTrackerResponse = await trackerService.create({
        deviceId: getNewTrackerUnaTagIdentifierWithoutZeroPrefix(),
        type: TrackerType.UnaTag,
      });

      if (!newUnaTagTrackerResponse.isSuccess) {
        toast.error("Error linking Asset to Tracker, couldn't create new UnaTag Tracker");
        return;
      }

      newUnaTagTrackerId = newUnaTagTrackerResponse.payload.id;
    }

    // Create new BLE Tracker
    if (existingBleTracker) {
      newBleTrackerId = existingBleTracker.id;
    } else if (newTrackerBleIdentifier) {
      const newBleTrackerResponse = await trackerService.create({
        deviceId: newTrackerBleIdentifier,
        type: TrackerType.BLE,
      });

      if (!newBleTrackerResponse.isSuccess) {
        toast.error("Error linking Asset to Tracker, couldn't create new BLE Tracker");
        return;
      }

      newBleTrackerId = newBleTrackerResponse.payload.id;
    }

    if (isNil(newBarcodeTrackerId) && isNil(newEpcTrackerId) && isNil(newUnaTagTrackerId) && isNil(newBleTrackerId)) {
      toast.error('Error linking Asset to Tracker, no Trackers could be created');
      return;
    }

    const trackerIdsToLink = [newBarcodeTrackerId, newEpcTrackerId, newUnaTagTrackerId, newBleTrackerId].filter(
      (id) => !isNil(id),
    ) as number[];

    if (!useExistingAsset) {
      if (selectedAssetTypeId === undefined) {
        toast.error('Error linking Asset to Tracker, no Asset Type selected');
        return;
      }

      // Create new Asset
      const newAsset = await assetService.create({
        assetTypeId: selectedAssetTypeId,
        code: newAssetCode,
      });

      if (!newAsset.isSuccess) {
        toast.error('Error linking Asset to Tracker, could not create new Asset');
        return;
      }

      const assetTrackerUpdateResponse = await assetService.updateTrackers(newAsset.payload.id, trackerIdsToLink);

      if (!assetTrackerUpdateResponse.isSuccess) {
        toast.error('Error linking Asset to Tracker, could not link Tracker to Asset');
        return;
      }

      toast.success('Asset linked to Tracker(s)');
      resetForm();
      return;
    } else {
      if (!existingAssetId) {
        toast.error('Error linking Asset to Tracker');
        return;
      }

      // Link Tracker to existing Asset
      const existingAssetResponse = await assetService.getById(existingAssetId);
      if (!existingAssetResponse.isSuccess) {
        toast.error('Error linking Asset to Tracker, could not fetch existing Asset');
        return;
      }

      const trackers = existingAssetResponse.payload.trackers.map((t) => t.id);
      const updatedTrackers = trackers.length > 0 ? [...trackers, ...trackerIdsToLink] : trackerIdsToLink;
      const assetTrackerUpdateResponse = await assetService.updateTrackers(existingAssetId, updatedTrackers);

      if (!assetTrackerUpdateResponse.isSuccess) {
        toast.error('Error linking Asset to Tracker, could not link Tracker to Asset');
        return;
      }

      toast.success('Asset linked to Tracker(s)');
      resetForm();
    }
  }

  function resetForm() {
    setNewAssetCode('');
    setExistingAsset(null);
    setNewTrackerBarcode('');
    setNewTrackerEpcCode('');
    setNewTrackerUnaTagIdentifier('');
    setNewTrackerBleIdentifier('');
    setValidationErrors([]);
  }

  return (
    <MobilePage>
      <MobilePageHeader title="Link a Tracker to an Asset" />

      <MobilePageContent>
        <div className="flex flex-col  gap-y-4 ">
          <div className="text-sm my-2">Select an Asset Type and either create a new Asset and/or Tracker, or connect existing ones.</div>
          <div>
            <div className="mb-2  font-semibold">Asset</div>
            <div className="mb-4">
              <CreateNewOrUseExistingToggleButton useExisting={useExistingAsset} onChange={onUseExistingAssetChanged} />
            </div>
            {useExistingAsset ? (
              <div className="flex items-start">
                <AssetSelect label="Asset" onSelected={onAssetSelected} selectedAssetId={existingAsset?.id} />
                <div className="ml-2 mt-2.5 flex-shrink-0">
                  <ScanBarcodeButton onScan={onExistingAssetCodeScanned} />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-y-2 mb-2">
                <div className="">
                  <AssetTypeSelect selectedAssetTypeId={selectedAssetTypeId} onChange={onSelectedAssetTypeIdChanged} />
                </div>
                <div className="flex items-start">
                  <TextField id="name" label="Asset Code" type="text" fullWidth value={newAssetCode} onChange={onNewAssetCodeChange} />
                  <div className="ml-2 mt-2.5 flex-shrink-0">
                    <ScanBarcodeButton onScan={onAssetCodeScanned} />
                  </div>
                </div>
              </div>
            )}
            <div>
              {useExistingAsset ? (
                <>
                  <div className="mb-2 font-semibold mt-2">Tracker</div>
                  <div className="mb-2">
                    <div className="mb-4">
                      <div className="font-medium">Existing Trackers</div>
                      {existingAsset ? (
                        existingAsset.trackers.map((tracker, i, array) => (
                          <div key={tracker.id} className="grid grid-cols-[125px_minmax(0,_1fr)] items-center  pt-1 ">
                            <div className="">
                              <TrackerTypeChip trackerType={tracker.type} />
                            </div>
                            <div className="tracking-wide">{tracker.barcode ?? tracker.deviceId ?? tracker.epc}</div>
                            {i < array.length - 1 && <div className="col-span-2 mt-1 h-px bg-gray-200 dark:bg-gray-700"></div>}
                          </div>
                        ))
                      ) : (
                        <div className="w-full mt-1 items-center text-center px-2 py-1 border-dashed border dark:border-gray-700 bg-gray-100 dark:bg-gray-800 rounded text-gray-600 dark:text-gray-400">
                          No existing trackers
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-1 font-medium">Add Trackers</div>
                </>
              ) : (
                <div className="mb-2 font-semibold">Add Trackers</div>
              )}
              <div className="flex items-start  ">
                <div className="w-24 mt-4 pt-0.5">UnaTag</div>
                <TextField id="name" type="text" fullWidth value={newTrackerUnaTagIdentifier} onChange={onNewTrackerUnaTagIdentifierChange} />
                <div className="ml-2 mt-2.5 flex-shrink-0">
                  <ScanBarcodeButton onScan={onNewTrackerUnaTagIdentifierScan} />
                </div>
              </div>
              <div className="flex items-start  ">
                <div className="w-24 mt-4 pt-0.5">BLE</div>
                <TextField id="name" type="text" fullWidth value={newTrackerBleIdentifier} onChange={onNewTrackerBleIdentifierChange} />
                <div className="ml-2 mt-2.5 flex-shrink-0">
                  <ScanBarcodeButton onScan={onNewTrackerBleIdentifierScan} />
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-start  ">
                <div className=" w-24 mt-4 pt-0.5">Barcode</div>
                <TextField id="name" type="text" fullWidth value={newTrackerBarcode} onChange={onNewTrackerBarcodeChange} />
                <div className="ml-2 mt-2.5 flex-shrink-0">
                  <ScanBarcodeButton onScan={onTrackerBarcodeScan} />
                </div>
              </div>
            </div>
            <div>
              <div className="flex items-start  ">
                <div className="w-24 mt-4 pt-0.5">RFID</div>
                <TextField id="name" type="text" fullWidth value={newTrackerEpcCode} onChange={onNewTrackerEpcCodeChange} />
                <div className="ml-2 mt-2.5 flex-shrink-0">
                  <ScanBarcodeButton onScan={onNewTrackerEpcCodeScan} />
                </div>
              </div>
            </div>

            {validationErrors.length > 0 && (
              <div className="mt-4">
                <Alert severity="error">
                  {validationErrors.map((error) => (
                    <div key={error}>- {error}</div>
                  ))}
                </Alert>
              </div>
            )}
            <div>
              <Button size="large" variant="contained" fullWidth className="mt-6" onClick={onLinkAssetClicked}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </MobilePageContent>
    </MobilePage>
  );
};

export default ConnectAssetPage;
