import { FunctionComponent, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useApiCall } from '@/hooks/useApiCall';
import { assetService } from '../api/assets/asset.service';
import { AssetModel } from '../types/AssetModel';
import { PagePaginationResultDto, PageSortOrder } from '@/lib/api/pagination.page.dto';
import { AssetSortOption } from '../api/assets/asset.contracts';
import { useExportToExcel } from '@/hooks/useExportToExcel';
import { FilterValues, FilterFieldName } from '@/components/filterbar/FilterBarContext';
import { CacheKey } from '@/providers/cache-provider/cache-key.enum';
import useQueryParamsFilters from '@/hooks/useQueryParamFilters';
import { GridApi, SortChangedEvent, GridReadyEvent } from 'ag-grid-community';
import dayjs from 'dayjs';
import { TrackerType } from '@/modules/trackers';
import { Button } from '@mui/material';
import { WidgetsRounded } from '@mui/icons-material';
import { TabbedPageLayoutBody } from '@/modules/application/components/TabbedPageLayoutBody';
import { TabbedLayout } from '@/modules/application/layouts/TabbedLayout';
import { TabbedLayoutTopBar } from '@/modules/application/components/TabbedLayoutTopBar';
import { TabMenuItem } from '@/modules/application/types/MenuItem';
import RequirePermissionComponent from '@/components/permissions/RequirePermissionComponent';
import { Permission } from '@/modules/users/submodules/roles/api/permissions.contracts';
import { ActionBar } from '@/modules/application';
import { MoveAssetsToLocationModal } from '@/modules/locations/components/MoveAssetsToLocationModal';
import PaginationControls from '@/components/grid/PaginationControls';
import PagedResultDataText from '@/components/filterbar/PagedResultDataText';
import ErrorLoadingDataAlert from '@/components/feedback/ErrorLoadingDataAlert';
import FilterBar from '@/components/filterbar/FilterBar';
import TextFilter from '@/components/filterbar/filters/TextFilter';
import LocationFilter from '@/components/filterbar/filters/LocationFilter';
import AssetTypeFilter from '@/components/filterbar/filters/AssetTypeFilter';
import NumberFilter from '@/components/filterbar/filters/NumberFilter';
import { ColumnID } from '@/components/grid/column-ids';
import FilterBarSearchButton from '@/components/filterbar/FilterBarSearchButton';
import { AssetsGrid } from '../components/AssetsGrid';

const ASSET_FETCH_LIMIT = 1000;

export const AssetsPage: FunctionComponent = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedRows, setSelectedRows] = useState<AssetModel[]>([]);
  const [isMoveAssetModalOpen, setIsMoveAssetModalOpen] = useState(false);

  const { setFiltersToUrl, filters } = useQueryParamsFilters(
    {
      pageNumber: searchParams.get('pageNumber') ? Number.parseInt(searchParams.get('pageNumber')!) : 1,
    },
    CacheKey.EVENT_FILTERS,
  );

  const {
    data: assetData,
    isLoading: assetIsLoading,
    isError: assetIsError,
    fetchData: fetchAssets,
    setApiCallArg,
  } = useApiCall<PagePaginationResultDto<AssetModel>>(() =>
    assetService.get(
      {
        searchText: filters.current.searchText,
        locationId: filters.current.locationId,
        assetTypeId: filters.current.assetTypeId,
        stayTimeInDays: filters.current.stayTimeInDays,
      },
      {
        page: filters.current.pageNumber,
        limit: ASSET_FETCH_LIMIT,
        order: filters.current.sortDirection,
        sort: filters.current.sortOption,
      },
    ),
  );

  const { exportToExcel } = useExportToExcel();

  const handleSearch = (filterValues: FilterValues, page?: number) => {
    setFiltersToUrl({
      ...filterValues,
      pageNumber: page ?? filters.current.pageNumber,
    });

    clearSelection();

    setApiCallArg(() =>
      assetService.get(
        {
          searchText: filterValues.searchText,
          locationId: filterValues.locationId,
          assetTypeId: filterValues.assetTypeId,
          stayTimeInDays: filterValues.stayTimeInDays,
        },
        {
          page: filterValues.pageNumber,
          limit: ASSET_FETCH_LIMIT,
          order: filterValues.sortDirection,
          sort: filterValues.sortOption,
        },
      ),
    );
  };

  const onGridReady = (event: GridReadyEvent<AssetModel>) => {
    // Apply initial sort state
    if (filters.current.sortOption && filters.current.sortDirection) {
      let columnId: ColumnID | undefined;

      switch (filters.current.sortOption) {
        case AssetSortOption.FIRST_EVENT_DATE:
          columnId = ColumnID.FIRST_EVENT_DATE;
          break;
        case AssetSortOption.LAST_EVENT_DATE:
          columnId = ColumnID.LAST_EVENT_DATE;
          break;
        case AssetSortOption.LOCATION_NAME:
          columnId = ColumnID.LOCATION_NAME;
          break;
        case AssetSortOption.ASSET_TYPE_NAME:
          columnId = ColumnID.ASSET_TYPE_NAME;
          break;
        case AssetSortOption.ASSET_CODE:
          columnId = ColumnID.ASSET_CODE;
          break;
        case AssetSortOption.LOCATION_ENTER_DATE:
          columnId = ColumnID.STAYTIME;
          break;
        default:
          break;
      }

      if (columnId) {
        event.api.applyColumnState({
          state: [
            {
              colId: columnId,
              sort: filters.current.sortDirection === PageSortOrder.ASC ? 'asc' : 'desc',
            },
          ],
          defaultState: { sort: null },
        });
      }
    }
  };

  const onSortChanged = (event: SortChangedEvent<AssetModel>) => {
    if (event.columns && event.columns.length > 0 && event.source === 'uiColumnSorted') {
      const changedColumn = event.columns[0];
      const changedColumnId = changedColumn.getColId();

      let sortOption: AssetSortOption | undefined;
      switch (changedColumnId) {
        case ColumnID.FIRST_EVENT_DATE:
          sortOption = AssetSortOption.FIRST_EVENT_DATE;
          break;
        case ColumnID.LAST_EVENT_DATE:
          sortOption = AssetSortOption.LAST_EVENT_DATE;
          break;
        case ColumnID.LOCATION_NAME:
          sortOption = AssetSortOption.LOCATION_NAME;
          break;
        case ColumnID.ASSET_TYPE_NAME:
          sortOption = AssetSortOption.ASSET_TYPE_NAME;
          break;
        case ColumnID.ASSET_CODE:
          sortOption = AssetSortOption.ASSET_CODE;
          break;
        case ColumnID.STAYTIME:
          sortOption = AssetSortOption.LOCATION_ENTER_DATE;
          break;
        default:
          break;
      }

      let sortOrder: PageSortOrder | undefined;
      switch (changedColumn.getSort()) {
        case 'asc':
          sortOrder = PageSortOrder.ASC;
          break;
        case 'desc':
          sortOrder = PageSortOrder.DESC;
          break;
        default:
          sortOption = undefined;
          break;
      }

      handleSearch(
        {
          ...filters.current,
          sortOption,
          sortDirection: sortOrder,
        },
        1,
      );
    }
  };

  async function onExportToExcelClicked(gridApi: GridApi<AssetModel> | undefined) {
    if (gridApi) {
      const data: AssetModel[] = [];
      const dataPage1 = await assetService.get(
        {
          searchText: filters.current.searchText,
          locationId: filters.current.locationId,
          assetTypeId: filters.current.assetTypeId,
          stayTimeInDays: filters.current.stayTimeInDays,
        },
        {
          page: 1,
          limit: ASSET_FETCH_LIMIT,
          order: filters.current.sortDirection ?? PageSortOrder.ASC,
          sort: filters.current.sortOption,
        },
      );

      if (dataPage1.isSuccess) {
        data.push(...dataPage1.payload.data);
      }

      // Fetch additional pages if needed
      for (let i = 2; i <= (dataPage1.payload.totalPages || 1); i++) {
        const dataPage = await assetService.get(
          {
            searchText: filters.current.searchText,
            locationId: filters.current.locationId,
            assetTypeId: filters.current.assetTypeId,
            stayTimeInDays: filters.current.stayTimeInDays,
          },
          {
            page: i,
            limit: ASSET_FETCH_LIMIT,
            order: filters.current.sortDirection ?? PageSortOrder.ASC,
            sort: filters.current.sortOption,
          },
        );

        if (dataPage.isSuccess) {
          data.push(...dataPage.payload.data);
        }
      }

      if (data.length > 0) {
        exportToExcel(
          data.map((asset) => ({
            code: asset.code,
            type: asset.assetType.name,
            location: asset.location?.name,
            firstEventDate: asset.firstEventDate,
            lastEventDate: asset.lastEventDate,
            stayTime: asset.locationEnteredDate ? dayjs().diff(dayjs(asset.locationEnteredDate), 'days') : undefined,
            ...asset.trackers.reduce<Record<string, string>>((acc, tracker, index) => {
              switch (tracker.type) {
                case TrackerType.Barcode:
                  acc[`tracker${index + 1}`] = tracker.barcode ?? '';
                  break;
                case TrackerType.RFID:
                  acc[`tracker${index + 1}`] = tracker.epc ?? '';
                  break;
                case TrackerType.UnaTag:
                case TrackerType.BLE:
                  acc[`tracker${index + 1}`] = tracker.deviceId ?? '';
                  break;
                default:
                  acc[`tracker${index + 1}`] = '';
                  break;
              }
              return acc;
            }, {}),
          })),
          `assets_${dayjs().format('YYYY_MM_DD_HHmmss')}.xlsx`,
        );
      }
    }
  }

  const onRowDoubleClicked = (assetId: string) => {
    navigate(`../${assetId}`);
  };

  // Asset moving logic
  const onMoveSelectedAssetsClicked = () => {
    setIsMoveAssetModalOpen(true);
  };

  const onMoveSelectedAssetsClosed = () => {
    setIsMoveAssetModalOpen(false);
    clearSelection();
  };

  const clearSelection = () => {
    setSelectedRows([]);
    // To deselect all rows in the grid, you may need to use a ref or a callback to access the grid API in AssetsGrid
  };

  const onAssetsMovedByGrid = () => {
    fetchAssets();
  };

  const onPageChanged = (event: React.ChangeEvent<unknown>, page: number) => {
    handleSearch(
      {
        ...filters.current,
        pageNumber: page,
      },
      page,
    );
  };

  const assetTabItems: TabMenuItem[] = [
    {
      activeRouteIds: ['/app/assets/overview'],
      label: 'Overview',
      url: '/app/assets/overview',
    },
  ];

  const hasSelectedRows = selectedRows.length > 0;

  return (
    <TabbedLayout
      topBar={
        <TabbedLayoutTopBar
          header="Assets"
          headerIcon={<WidgetsRounded fontSize={'small'} />}
          tabItems={assetTabItems}
          actions={
            <RequirePermissionComponent permission={Permission.ASSETS_EDIT}>
              <Button variant="contained" onClick={() => navigate({ pathname: '../create' })}>
                Create
              </Button>
            </RequirePermissionComponent>
          }
        />
      }
    >
      <TabbedPageLayoutBody>
        <div className="flex h-full flex-grow flex-col ">
          {assetIsError ? (
            <ErrorLoadingDataAlert />
          ) : (
            <>
              <div className="mb-2 flex items-center justify-between">
                <FilterBar onSearch={handleSearch} initialFilterValues={filters.current} showAsPopover>
                  <div className="flex flex-col gap-y-2">
                    <div className="w-72">
                      <TextFilter label="Search" />
                    </div>
                    <div className="w-72">
                      <LocationFilter label="Location" />
                    </div>
                    <div className="w-72">
                      <AssetTypeFilter label="Asset Type" />
                    </div>
                    <div className="w-72">
                      <NumberFilter label="Minimum Stay Time (days)" filterFieldName={FilterFieldName.stayTimeInDays} />
                    </div>
                  </div>
                  <FilterBarSearchButton isLoading={assetIsLoading} />
                </FilterBar>
              </div>
              <AssetsGrid
                data={assetData?.data}
                isLoading={assetIsLoading}
                onSelectionChanged={setSelectedRows}
                onRowDoubleClicked={onRowDoubleClicked}
                onExportToExcelClicked={onExportToExcelClicked}
                onSortChanged={onSortChanged}
                onGridReady={onGridReady}
              />
              <div className="flex items-center gap-x-4">
                <PaginationControls
                  isLoading={assetIsLoading}
                  totalPageCount={assetData?.totalPages ?? 1}
                  currentPage={filters.current.pageNumber ?? 1}
                  totalElements={assetData?.totalElements ?? 0}
                  onChange={onPageChanged}
                />
                <PagedResultDataText data={assetData} name="Assets" />
              </div>
            </>
          )}
        </div>
        <ActionBar visible={hasSelectedRows}>
          <Button variant="outlined" color="primary" onClick={clearSelection}>
            Clear Selection
          </Button>
          <Button variant="contained" onClick={onMoveSelectedAssetsClicked}>
            Move Assets...
          </Button>
        </ActionBar>
        <MoveAssetsToLocationModal
          selectedAssetIds={selectedRows.map((asset) => asset.id)}
          isOpen={isMoveAssetModalOpen}
          onClose={onMoveSelectedAssetsClosed}
          onAssetsMoved={onAssetsMovedByGrid}
        />
      </TabbedPageLayoutBody>
    </TabbedLayout>
  );
};
