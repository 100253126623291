import { isNil } from 'lodash-es';
import { TrackerType } from '../api/trackers/tracker.contracts';
import { TFunction } from 'i18next';

export function trackerTypeToString(type: TrackerType | undefined, t: TFunction) {
  if (isNil(type)) {
    return '';
  }
  switch (type) {
    case TrackerType.Barcode:
      return t('tracker.type.barcode');

    case TrackerType.RFID:
      return t('tracker.type.rfid');

    case TrackerType.UnaTag:
      return t('tracker.type.unatag');

    case TrackerType.BLE:
      return t('tracker.type.ble');
    default:
      return '';
  }
}

export function trackerUniqueIdentifier(
  tracker: {
    type: TrackerType;
    epc?: string | null;
    barcode?: string | null;
    deviceId?: string | null;
  },
  t: TFunction,
) {
  const unknownTracker = t('tracker.unknown');
  switch (tracker.type) {
    case TrackerType.Barcode:
      return tracker.barcode ? tracker.barcode : unknownTracker;

    case TrackerType.RFID:
      return tracker.epc ? tracker.epc : unknownTracker;

    case TrackerType.UnaTag:
    case TrackerType.BLE:
      return tracker.deviceId ? tracker.deviceId : unknownTracker;

    default:
      return unknownTracker;
  }
}
